import { Modal, Row, Tabs, Col, Avatar, notification, Tooltip } from 'antd'
import React, { useState, useEffect, useCallback } from 'react'
import Magnifier from 'react-magnifier'
import { useDispatch, useSelector } from 'react-redux'
import ContentCategoryData from '../AllCategories/Content'
import MaterialOneCategoryData from '../AllCategories/MaterialOne'
import MaterialTwoCategoryData from '../AllCategories/MaterialTwo'
import ArtistProfile from '../ArtistProfile'
import { useParams, useHistory } from 'react-router-dom';

import Questions from '../Questions'
import { removeCartItem } from '../../../../../redux/work/actionCreator';
import count from '../../../../../redux/count/actionCreator';
import { Button } from '../../../../../components/buttons/buttons';
import getdata from '../../../../../redux/contentChange/actions'
import getTabKey from '../../../../../redux/TabKeyChange/actions'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import messages from '../../../../../config/constants/messages';
import question_icon from '../../../../../static/img/question-icon.svg';
import cart_icon from '../../../../../static/img/cart-icon.svg';
import calendar_icon from '../../../../../static/img/calendar-icon.svg';
import dimensions_icon from '../../../../../static/img/dimensions-icon.svg'
import materials_icon from '../../../../../static/img/materials-icon.svg'
import notes_icon from '../../../../../static/img/notes-icon.svg'
import cart_icon_hover from '../../../../../static/img/cart-icon-hover.svg';
import leading_icon from '../../../../../static/img/leading-icon.svg';
import default_user_icon from '../../../../../static/img/user.png';
import './css/style.css'
import config from '../../../../../../src/config/config';
import workService from '../../../../../../src/services/workService';
import gtag from 'ga-gtag';
//src/services/workService
import axios from 'axios';
import { Helmet } from 'react-helmet';

const ArtWorkDetailsModal = ({ is_disable_cart, work_id, metaData, discounted_price, early_access_id }) => {

    const history = useHistory();

    const { TabPane } = Tabs;
    const dispatch = useDispatch();

    const [state, setState] = useState({
        signInVisible: false,
        offerVisible: false
    });

    const UID = useSelector((state) => {
        return state.fb.auth.uid;
    });
    const email = useSelector((state) => {
        return state.fb.auth.email;
    });

    const handleMouseEnter = useCallback((iconName) => {
        setCartHoverIcon(true);
    }, []);

    const handleMouseLeave = useCallback((iconName) => {
        setCartHoverIcon(false);
    }, []);


    const [loading, setLoading] = useState(true);

    const { signInVisible, offerVisible } = state;
    const [singleWorkDetails, setSingleWorkDetails] = React.useState(false);

    if (singleWorkDetails.length > 0) {
        var allImages = JSON.parse(singleWorkDetails[0].image_urls)
        var logoImage = JSON.parse(singleWorkDetails[0].logo)

        var { appliedCategoriesAttributes } = ContentCategoryData(singleWorkDetails[0].categories)
        var { appliedMaterialsXAttributes } = MaterialOneCategoryData(singleWorkDetails[0].materials1)
        var { appliedMaterialsYAttributes } = MaterialTwoCategoryData(singleWorkDetails[0].materials2)
    }



    // onclick to Image change in model

    const [dynamicImageChange, setDynamicImageChange] = useState('')
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [foundCart, setFoundCart] = useState(false)

    const [cartHoverIcon, setCartHoverIcon] = useState(false)

    const getimgurl = (url) => {

        setDynamicImageChange(url)
    }


    const handledefaultActiveTabKey = (tabValue) => {

        if (tabValue == "1") {
            dispatch(getTabKey.getTabKeySuccess({
                defaultActiveTabKey: "2"
            }));
        }
        else {
            dispatch(getTabKey.getTabKeySuccess({
                defaultActiveTabKey: "1"
            }));
        }
    }
    useEffect(() => {
        handledefaultActiveTabKey(2)

    }, [])

    const WorkDetailsGet = (workId, work_title) => {
        if (UID != undefined) {

            const workData = {
                UID: UID,
                workId: workId,
                gallery_index: 0,
                early_access_id: early_access_id,
                discounted_price: discounted_price
            }
            const data = workService.addCart(workData).then(res => {
                setFoundCart(true)
                dispatch(count(UID));
            });
            GAEvent('wl_details_add_to_cart', work_title)


        } else {
            const workData = [{
                workId,
                discounted_price,
                early_access_id
            }]
            let checkDataExist = JSON.parse(sessionStorage.getItem('cart'))
            if (checkDataExist) {
                checkDataExist = checkDataExist.filter((item) => item.workId !== workId);

                var storeNewDataIntoSessionStorage = [...checkDataExist, { workId, discounted_price, early_access_id }]
                sessionStorage.setItem("cart", JSON.stringify(storeNewDataIntoSessionStorage))

                let getcount = sessionStorage.getItem('cartItemCount')
                sessionStorage.setItem('cartItemCount', Number(getcount) + Number(1))

            } else {
                sessionStorage.setItem('cart', JSON.stringify(workData))
                sessionStorage.setItem('cartItemCount', Number(1))
            }
            let cart = sessionStorage.getItem('cart')
            let parseCartData = JSON.parse(cart)
            let cartLength = parseCartData.length
            handleCartCount(cartLength)
            GAEvent('wol_details_add_to_cart', work_title)
        }
        checkArtWorkStatus(workId)
    }

    const handleCartCount = (count) => {

        dispatch(getdata.getContentDetailsSuccess({
            count
        }));
    }

    const checkArtWorkStatus = (workId) => {
        if (workId !== 0 && UID != undefined) {
            let post_data = { id: workId, uid: UID };
            axios.post(`${config.apiUrl}/check-artwork-status`, post_data)
                .then((response) => {
                    if (response.status == 200) {
                        //alert(JSON.stringify(response.data));
                        if (response.data) {
                            setFoundCart(false);
                            if (response.data.is_cart == 1) {
                                setFoundCart(true);
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.log("Error :", error)
                })
        } else {
            let cartdata = JSON.parse(sessionStorage.getItem('cart'))
            const value = cartdata != null ? cartdata.filter(item => item.workId == workId) : []
            if (value && value.length > 0) {
                setFoundCart(true);
            } else {
                setFoundCart(false);
            }
        }
    }



    useEffect(() => {
        var name = sessionStorage.getItem('name') != null ? sessionStorage.getItem('name') : 'Anonymous';
        let view_data = UID != undefined ? { work_id: work_id, email: email, uid: UID, name: name } : { work_id: work_id, email: 'Anonymous', uid: 'Anonymous', name: name }
        storeClickEvent(view_data);

        if (work_id) {
            const result = work_id;
            fetch(`${config.apiUrl}/get-single-work-details/${result}`)
                .then(async response => {
                    console.log('get getSingleWorkDetails response')
                    // console.log(response)

                    if (response.ok) {
                        return await response.json().then(json => {
                            setSingleWorkDetails(json);
                            return json;
                        });
                    }
                });
            console.log("singleWorkDetails", singleWorkDetails)
            checkArtWorkStatus(work_id)
        }

    }, [])

    const storeClickEvent = (post_data) => {

        axios.post(`${config.apiUrl}/store-artwork-click-event`, post_data)
            .then((response) => {
                if (response.status == 200) {
                    console.log("Store Events");
                    //alert(JSON.stringify(response.data));
                }
            })
            .catch((error) => {
                console.log("Error :", error)
            })
    }


    var description = '';
    var truncatedDescription = '';

    if (singleWorkDetails.length > 0) {
        description = singleWorkDetails.length > 0 ? singleWorkDetails[0].work_description : '';
        truncatedDescription = description.length > 450 ? description.slice(0, 450) + '...' : description;
    }



    const defaultActiveTabKey = useSelector((state) => {
        return state.tabKeyValue.defaultActiveTabKey
    })


    const showQuestionsModal = (type) => {
        if (type == 'offer') {
            setState({
                ...state,
                offerVisible: true,
            });
        } else {
            setState({
                ...state,
                signInVisible: true,
            });
        }

    };

    const onCancel = (type) => {
        if (type == 'offer') {
            setState({
                ...state,
                offerVisible: false
            });
        } else {
            setState({
                ...state,
                signInVisible: false
            });
        }
    };

    const goToArtWorkDiv = (id) => {
        const divElement = document.getElementById(id);
        divElement.scrollIntoView({ behavior: 'smooth' });
    }

    const shareLink = () => {
        notification.success({
            message: messages['copied_to_clipboard']
        });
    };

    const [confirmLoading, setConfirmLoading] = React.useState(false);

    const delectCartItem = (ID, work_title) => {

        if (UID !== undefined) {
            var clickedId = [{
                id: ID
            }];
            dispatch(removeCartItem(ID, UID));
            setFoundCart(false);
            setTimeout(() => {
                //alert(1);
                dispatch(count(UID));
            }, 1000);
            GAEvent('wl_details_remove_cart', work_title)
        } else {
            var clickedId = [{
                id: ID
            }];

            let cartdata = JSON.parse(sessionStorage.getItem('cart'))

            let manageCartCount = cartdata.filter(o1 => !clickedId.some(o2 => o1.workId === o2.id));

            sessionStorage.setItem('cart', JSON.stringify(manageCartCount))

            let cartLength = manageCartCount.length
            setFoundCart(false);
            handleCartCount(cartLength)
            GAEvent('wol_details_remove_cart', work_title)
        }

    }

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };
    const GAEvent = (event, title) => {
        gtag('event', event, {
            poll_title: event + ' ' + title,
            artwork_name: title
        })
    };


    return (
        <>
            {metaData == true &&
                <>
                    <Helmet>
                        <title>{singleWorkDetails.length > 0 && singleWorkDetails[0].work_title}</title>
                        <meta name="description" content={description} />
                        <meta name="keywords" content="" />
                        <meta name="author" content="stoodio" />
                        <meta name="robots" content="" />
                        <meta property="og:type" content="website" />
                        <meta property="og:site_name" content="https://stoodio.art/" />
                        <meta property="og:title" content={singleWorkDetails.length > 0 && singleWorkDetails[0].work_title} />
                        <meta property="og:description" content={description} />
                        <meta property="og:image" content="" />
                        <meta property="og:robots" content="" />
                        <meta property="og:image:secure_url" content="" />
                        <meta property="og:url" content={singleWorkDetails.length > 0 && `https://stoodio.art/artwork-for-sale/` + singleWorkDetails[0].work_title.replace(/[^a-zA-Z]/g, '').replaceAll(' ', '-') + '-' + singleWorkDetails[0].id} />
                    </Helmet>
                </>
            }
            <Row style={{ marginTop: '30px' }}>

                <Col className='work-modal' md={12} sm={24} xs={24}>
                    {
                        allImages && allImages.length > 0 &&
                        <Magnifier
                            src={dynamicImageChange ? dynamicImageChange : allImages && allImages[0].url}
                            mgShape='square'
                            style={{
                                borderRadius: 8,
                                cursor: 'pointer',
                                marginBottom: 10,
                            }}
                        />
                    }
                    <br />
                    {
                        allImages &&
                        allImages.map((images) => {
                            return (
                                <img src={images.url} style={{ height: 60, width: 60, borderRadius: 8, marginRight: 6, marginTop: 6 }} onClick={() => { getimgurl(images.url) }} />
                            )
                        })
                    }
                </Col>

                <Col md={12}>
                    <h2 className='artwork-title' style={{ fontFamily: 'Melodrama' }}>
                        {singleWorkDetails.length > 0 && singleWorkDetails[0].work_title}
                    </h2>
                    <Row style={{ marginBottom: '35px' }}>
                        <Col xs={4} sm={3} md={3} lg={2} xl={2} style={{ display: 'flex' }}>
                            <span>
                                {
                                    logoImage ?
                                        <img src={logoImage[0].url} alt='user-profile' className='artwork-logo' />
                                        :
                                        <img src={default_user_icon} alt='user-profile' className='artwork-logo' />
                                }
                            </span>
                        </Col>
                        <Col xs={20} sm={21} md={21} lg={22} xl={22} >
                            <p className='artwork-artistname' onClick={() => goToArtWorkDiv('artist-bio-div')} >{singleWorkDetails.length > 0 && singleWorkDetails[0].name}</p>
                            <p className='other-artwork-title' onClick={() => goToArtWorkDiv('other-artwork-div')}>See other artworks</p>

                        </Col>
                    </Row>


                    {/* <span style={{ fontSize: '15px', fontWeight: 500 }}>
                                                            by {singleWorkDetails.length > 0 && singleWorkDetails[0].name}
                                                        </span> */}


                    {(singleWorkDetails && singleWorkDetails.length > 0) ?
                        (<>

                            <span className='artwork-properties'>
                                <p><img src={calendar_icon} style={{ marginBottom: '4px', marginRight: '16px' }} />{singleWorkDetails.length > 0 && singleWorkDetails[0].year_created}</p>
                                <p><img src={dimensions_icon} style={{ marginBottom: '4px', marginRight: '16px' }} />{singleWorkDetails.length > 0 && singleWorkDetails[0].width} x {singleWorkDetails.length > 0 && singleWorkDetails[0].height} in</p>
                                <p>
                                    <img src={materials_icon} style={{ arginBottom: '4px', marginRight: '16px' }} />
                                    {
                                        singleWorkDetails.length > 0 &&
                                        appliedMaterialsXAttributes && appliedMaterialsXAttributes.join(', ').toLowerCase()
                                    }

                                    {
                                        singleWorkDetails.length > 0 && appliedMaterialsYAttributes.length > 0 &&
                                        <span style={{ textTransform: 'lowercase' }}>&nbsp;on&nbsp;{appliedMaterialsYAttributes && appliedMaterialsYAttributes.join(', ').toLowerCase()}</span>
                                    }
                                </p>
                                <p>
                                    <img src={notes_icon} style={{ arginBottom: '4px', marginRight: '16px' }} />
                                    {
                                        singleWorkDetails.length > 0 &&
                                            singleWorkDetails[0].is_framed == 0 ? <span>This artwork arrives unframed.</span> : <span>This artwork arrives framed.</span>
                                    }
                                </p>
                            </span>
                        </>)
                        : (<i className="fa fa-spinner fa-spin" style={{ fontSize: 18, marginLeft: 5 }} />)
                    }

                    <div style={{ alignItems: 'flex-start' }}>
                        {
                            singleWorkDetails.length > 0 && singleWorkDetails[0].sold != 1 && singleWorkDetails[0].sold != 2 &&
                            <>
                                {foundCart == true ?
                                    <Button type="info" outlined size='default' style={{ marginTop: 30, marginLeft: 8, border: '1px solid #C2E4F2' }} onClick={() => { delectCartItem(singleWorkDetails[0].id, singleWorkDetails[0].work_title) }} className='add-cart-button-text artwork-button'>
                                        Remove from cart&nbsp;&nbsp;&nbsp;X
                                    </Button>
                                    :
                                    <>
                                        {/* <button size='default' class="slide-from-bottom add-cart-button-text" disabled={singleWorkDetails.length > 0 && singleWorkDetails[0].sold == '1' ? true : false} onClick={() => { WorkDetailsGet(singleWorkDetails[0].id) }}>
                                ${(singleWorkDetails.length > 0 && singleWorkDetails[0].price != null && singleWorkDetails[0].price != '') ? parseFloat(singleWorkDetails[0].price).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '0.00'}
                                &nbsp;<span style={{ fontWeight: 400 }}>|</span><img src={cart_icon} style={{ width: '24px', height: '24px', marginLeft: '10px' }} />
                            </button> */}
                                        <button style={{ cursor: 'pointer' }} onMouseEnter={() => handleMouseEnter('cartIcon')} onMouseLeave={() => handleMouseLeave('cartIcon')} size='default' class="slide-from-bottom add-cart-button-text" onClick={() => { WorkDetailsGet(singleWorkDetails[0].id, singleWorkDetails[0].work_title) }}>
                                            {
                                                discounted_price != null ?
                                                    <>
                                                        <span className='hover-black-color-change-price' style={{fontSize: '1.0em',color:'#fff',textDecoration:'line-through',textDecorationThickness: '2px'}}>${(singleWorkDetails.length > 0 && singleWorkDetails[0].price != null && singleWorkDetails.length > 0 && singleWorkDetails[0].price != '') ? parseFloat(singleWorkDetails.length > 0 && singleWorkDetails[0].price).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '0.00'}&nbsp;&nbsp;<span style={{ fontWeight: 400 }}></span></span>&nbsp;&nbsp;
                                                       <span className='hover-color-change-price' style={{fontSize: '1.0em'}}> ${(discounted_price && discounted_price != null  && discounted_price != '') ? parseFloat(singleWorkDetails.length > 0 && discounted_price).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '0.00'}&nbsp;&nbsp;</span> <span style={{ fontWeight: 400 }}>|</span>
                                                    </>
                                                    :
                                                    <>
                                                        ${(singleWorkDetails.length > 0 && singleWorkDetails[0].price != null && singleWorkDetails.length > 0 && singleWorkDetails[0].price != '') ? parseFloat(singleWorkDetails.length > 0 && singleWorkDetails[0].price).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '0.00'}&nbsp;&nbsp;<span style={{ fontWeight: 400 }}>|</span>
                                                    </>
                                            }
                                            {/* $ {(singleWorkDetails.length > 0 && singleWorkDetails[0].price != null && singleWorkDetails.length > 0 && singleWorkDetails[0].price != '') ? parseFloat(singleWorkDetails.length > 0 && singleWorkDetails[0].price).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '0.00'}&nbsp;&nbsp;<span style={{ fontWeight: 400 }}>|</span> */}
                                            {
                                                !cartHoverIcon && <img src={cart_icon} style={{ width: '20px', height: '20px', marginLeft: '5px' }} />
                                            }
                                            {
                                                cartHoverIcon && <img src={cart_icon_hover} style={{ width: '20px', height: '20px', marginLeft: '5px' }} />
                                            }
                                        </button>

                                        {/*  <button  disabled={singleWorkDetails.length > 0 && singleWorkDetails[0].sold == '1' ? true : false} onClick={() => { WorkDetailsGet(singleWorkDetails[0].id) }} style={{ marginTop: 30, marginLeft: 8 }} className='add-cart-button'>
                                       ${(singleWorkDetails.length > 0 && singleWorkDetails[0].price != null && singleWorkDetails[0].price != '') ? parseFloat(singleWorkDetails[0].price).toFixed(2) : '0.00'}&nbsp;|&nbsp;<img src={cart_icon} style={{ width: '24px', height: '24px', marginLeft: '10px' }} />
                                     </button> */}
                                    </>
                                }
                            </>
                        }

                        <Button type="info" outlined size='default' style={{ marginTop: 30, marginLeft: 8, border: '1px solid #C2E4F2' }} onClick={() => { showQuestionsModal('') }} className='add-cart-button-text artwork-button'>
                            <img src={question_icon} />&nbsp;Questions?
                        </Button>
                        <Tooltip placement="bottomRight" title="Click to share">
                            <CopyToClipboard text={singleWorkDetails.length > 0 && `${config.shareUrl}/artwork-for-sale/` + singleWorkDetails[0].work_title.replace(/[^a-zA-Z]/g, '').replaceAll(' ', '-') + '-' + singleWorkDetails[0].id}>
                                <Button type="info" outlined size='default' style={{ marginTop: 30, marginLeft: 8, border: '1px solid #C2E4F2' }} onClick={() => { shareLink() }} className='add-cart-button-text artwork-button'>
                                    <img src={leading_icon} />
                                </Button>
                            </CopyToClipboard>
                        </Tooltip><br />
                        {
                            singleWorkDetails.length > 0 && singleWorkDetails[0].sold != 1 && singleWorkDetails[0].sold != 2 &&
                            <p onClick={() => { showQuestionsModal('offer') }} style={{ margin: '20px 0px' }} className='other-artwork-title'><u>Make an offer</u></p>
                        }

                    </div>
                    <Questions onCancel={() => { onCancel('') }} visible={signInVisible} artWorkId={singleWorkDetails.length && singleWorkDetails[0].id} offer={false} />
                    <Questions onCancel={() => { onCancel('offer') }} visible={offerVisible} artWorkId={singleWorkDetails.length && singleWorkDetails[0].id} offer={true} />


                    <h5 className='artwork-description-title' style={{ fontFamily: 'Melodrama', marginTop: 30 }}>Description</h5>

                    <div>
                        <Row>
                            <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                                <p className='artwork-description'>
                                    {singleWorkDetails.length > 0 && (
                                        <>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: showFullDescription ? description : truncatedDescription,
                                                }}
                                                style={{ wordBreak: 'break-word' }}
                                            ></div>
                                            {description.length > 450 && (
                                                <p onClick={toggleDescription} className='add-cart-button-text' style={{ color: '#006c94' }}>
                                                    {showFullDescription ? 'Show less' : 'Show more'}
                                                </p>
                                            )}
                                        </>
                                    )}
                                    {/* {
                                    singleWorkDetails.length > 0 && 450 < singleWorkDetails.length && (
                                        <><div dangerouslySetInnerHTML={{ __html: singleWorkDetails[0].work_description != "" ? singleWorkDetails[0].work_description : "" }} style={{ wordBreak: 'break-word' }}></div></>
                                    )
                                }
                                {
                                    singleWorkDetails.length > 0 && singleWorkDetails.length <= 451 && {}
                                } */}

                                </p>
                            </Col>

                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center', height: 50 }}>
                    <hr style={{ border: '0.5px solid', width: '60%', borderColor: '#dadada' }} />
                </Col>

                {
                    singleWorkDetails.length > 0 &&
                    <ArtistProfile
                        UID={singleWorkDetails.length > 0 && singleWorkDetails[0].user_id}
                        artistName={singleWorkDetails.length > 0 && singleWorkDetails[0].name}
                        bio={singleWorkDetails.length > 0 && singleWorkDetails[0].bio}
                        currentArtWorkId={singleWorkDetails.length > 0 && singleWorkDetails[0].id}
                        artistImage={singleWorkDetails.length > 0 && singleWorkDetails[0].artist_image}
                        isModal={false}
                        exhibition_history={singleWorkDetails.length > 0 && singleWorkDetails[0].exhibition_history}
                        education_history={singleWorkDetails.length > 0 && singleWorkDetails[0].education_history}

                    />
                }

            </Row>
        </>


    )
}

export default ArtWorkDetailsModal