import React, { useEffect, useState, lazy } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ReactReduxFirebaseProvider, isLoaded } from 'react-redux-firebase';
import { ConfigProvider, Spin } from 'antd';
import store, { rrfProps } from './redux/store';
import Admin from './routes/admin';
import Users from './routes/users';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import Home from './container/profile/authentication/overview/Home';

const { theme } = config;

const ProviderConfig = () => {
  const { rtl, isLoggedIn, topMenu, darkMode, auth } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
      auth: state.fb.auth,
    };
  });

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          {!isLoaded(auth) ? (
            <div className="spin">
              <Spin />
            </div>
          ) : (
            <Router basename={process.env.PUBLIC_URL}>
              
              {!isLoggedIn ? <Route path="/" component={Auth} /> : <ProtectedRoute path="/admin" component={Users} /> }
              
              
              {isLoggedIn && 
              <Switch>
              
              <Route path="/" component={Users} />
              <Route path="/gallery" component={Users} />
              <Route path="/checkout" component={Users} />
              <Route path="/for-collectors" component={Users} />
              <Route path="/for-artists" component={Users} />
              <Route path="/profile" component={Users} />
              <Route path="/my-works/grid" component={Users}/>
              <Route path="/project/view/list" component={Users}/>
              <Route path={`/invite`} component={Users} />
              <Route path={`/users`} component={Users} />
              <Route path={`/cart`} component={Users} />
              <Route path={`/art-works`} component={Users} />
              <Route path={`/admin-art-works`} component={Users} />
              <Route path={`/my-ratings`} component={Users} />
              <Route path={`/taste-quiz`} component={Users} />
              <Route path="/stoodio-snippet" component={Users} />
              <Route path={`/for-artist-snippet`} component={Users} />
              <Route path={`/for-collector-snippet`} component={Users} />
              <Route path="/purchase-sucess" component={Users} />
              <Route path={`/data-not-found`} component={Users} />
              <Route path="/privacy-policy" component={Users} />
              <Route path="/our-partners" component={Users} />
              {/* <Route path="/early-work" component={Users} /> */}
              <Route path="/terms" component={Users} />
              <Route path={`/artwork-for-sale/:artworkname`}  component={Users} />              
              
              <Route path={`/my-purchases`} component={Users} />
              <Route path={`/manage-exhibition`} component={Users} />
              <Route path={`/manage-early-work`} component={Users} />
              <Route path={`/hold-orders`} component={Users} />
            </Switch>
              }

              {/* {isLoggedIn && <Route path="/" component={Users} />
              } */}
            </Router>
          )}
        </ReactReduxFirebaseProvider>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
