import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';

const Login = lazy(() => import('../container/profile/authentication/overview/FbSignIn'));
const SignUp = lazy(() => import('../container/profile/authentication/overview/FbSignup'));
const SignUpNew = lazy(() => import('../container/profile/authentication/overview/EmailSignup'));
const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));
const LoginHomeGallery = lazy(() => import('../container/profile/authentication/overview/LoginHomeGallery'));
const Home = lazy(() => import('../container/profile/authentication/overview/Home'));
const ForArtist = lazy(() => import('../container/profile/authentication/overview/ForArtist'));
const ForCollector = lazy(() => import('../container/profile/authentication/overview/ForCollector'));
const WithoutLoginGallery = lazy(() => import('../container/profile/authentication/overview/WithoutLoginGallery'));
const Checkout = lazy(() => import('../container/profile/authentication/overview/GalleryComponents/TempCheckout'));
const PolicyPage = lazy(() => import('../container/profile/authentication/overview/GalleryComponents/PolicyPage'));
const OurPartners = lazy(() => import('../container/profile/authentication/overview/GalleryComponents/OurPartners'));
const Exhibitions = lazy(() => import('../container/profile/authentication/overview/GalleryComponents/Exhibitions'));
const ExhibitionsList = lazy(() => import('../container/profile/authentication/overview/GalleryComponents/ExhibitionList'));
const BlogsList = lazy(() => import('../container/profile/authentication/overview/GalleryComponents/BlogList'));
const EducationList = lazy(() => import('../container/profile/authentication/overview/GalleryComponents/EducationList'));
const Terms = lazy(() => import('../container/profile/authentication/overview/GalleryComponents/Terms'));
const ArtGallery = lazy(() => import('../container/profile/authentication/overview/GalleryComponents/ArtGallery'));
const ArtistGrid = lazy(() => import('../container/profile/authentication/overview/GalleryComponents/ArtistGrid'));
import SucessfulPurchase from '../container/profile/authentication/overview/GalleryComponents/SucessfulPurchase';
import ForgotPassword from '../container/profile/authentication/overview/GalleryComponents/FotgotPassword';
import ChangeRole from '../container/profile/authentication/overview/GalleryComponents/ChangeRole';
import ArtWorkDetails from '../container/profile/authentication/overview/GalleryComponents/ArtWorkDetails';
import HomePageContent from '../container/profile/authentication/overview/GalleryComponents/HomePageContent';
import CustomShare from '../container/profile/authentication/overview/GalleryComponents/CustomShare';
import DataNotFound from '../container/profile/authentication/overview/GalleryComponents/DataNotFound';
import NewsEventList from '../container/profile/authentication/overview/GalleryComponents/NewsEventList';
import EducationDetails from '../container/educations/EducationDetails';
import BlogDetails from '../container/blogs/BlogDetails';
import NewsDetails from '../container/newsevents/NewsDetails';
import NewsEventDetails from '../container/profile/authentication/overview/GalleryComponents/NewsEventDetails';
import ArtistDetails from '../container/profile/authentication/overview/GalleryComponents/ArtistDetails';
import PrivateMembership from '../container/profile/authentication/overview/GalleryComponents/PrivateMembership';
import EarlyAccessDetail from '../container/profile/authentication/overview/GalleryComponents/EarlyAccessDetail';
const NotFound = () => {
  // alert("auth.js")
  return <Redirect to="/" />;
};

const FrontendRoutes = () => {
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        {/* Without Login Routes */}
        <Route exact path="/forgotPassword" component={ForgotPass} />
        <Route exact path={`/artwork-for-sale/:artworkname`} component={ArtWorkDetails} />
        <Route exact path="/register" component={SignUp} />
        <Route exact path="/signup/:email" component={SignUpNew} />
        <Route exact path="/for-artists" component={ForArtist} />
        <Route exact path="/for-collectors" component={ForCollector} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/gallery" component={LoginHomeGallery} />
        <Route exact path="/view-gallery" component={WithoutLoginGallery} />
        <Route exact path="/tastequiz" component={WithoutLoginGallery} />
        <Route exact path="/check-out" component={Checkout} />
        <Route exact path="/privacy-policy" component={PolicyPage} />
        <Route exact path="/our-partners" component={OurPartners} />
        <Route exact path="/exhibitions/:exhibitionname" component={Exhibitions} />
        {/* <Route exact path="/early-access/:early_access_id" component={EarlyAccessDetail} /> */}
        <Route exact path="/early-access" component={EarlyAccessDetail} />
        <Route exact path="/exhibitions" component={ExhibitionsList} />
        <Route exact path="/blogs" component={BlogsList} />
        <Route exact path="/education" component={EducationList} />
        <Route exact path="/education/:url" component={EducationDetails} />
        <Route exact path="/blogs/:url" component={BlogDetails} />
        <Route exact path="/all-artworks" component={ArtGallery} />
        <Route exact path="/all-artworks/:filterValues" component={ArtGallery} />
        <Route exact path="/all-artworks/:share_url/:share_id" component={ArtGallery} />
        <Route exact path="/artworks/:SearchValues" component={CustomShare} />
        
        <Route exact path="/artists" component={ArtistGrid} />
        <Route exact path={`/artists/:artistId`} component={ArtistDetails} />
        <Route exact path="/terms" component={Terms} />
        
        <Route path={`/purchase-sucess`} component={SucessfulPurchase} />
        <Route path={`/data-not-found`} component={DataNotFound} />
        <Route path={`/reset-password`} component={ForgotPassword} />
        {/* <Route exact path="/early-work" component={PrivateMembership} /> */}
        <Route path={`/change-role/:token`} component={ChangeRole} />
        
         {/* Login Routes */}
        <Route exact path="/" component={HomePageContent} />
        <Route exact path="/settings" component={NotFound} />
        <Route exact path="/invite" component={NotFound} />        
        <Route exact path="/my-works/grid" component={NotFound} />
        <Route exact path="/admin-taste-test" component={NotFound} />
        <Route exact path="/users" component={NotFound} />
        <Route exact path="/checkout" component={NotFound} />
        {/* <Route exact path="/for-artist" component={NotFound} /> */}
        {/* <Route exact path="/for-collectors" component={NotFound} /> */}
        <Route exact path="/art-works" component={NotFound} />
        <Route exact path="/art-works-actions" component={NotFound} />
        <Route exact path="/my-ratings" component={NotFound} />        
        <Route exact path="/profile" component={NotFound} />
        {/* <Route exact path="/taste-test-quiz" component={NotFound} />
        <Route exact path="/gallery" component={NotFound} /> */}
        <Route exact path={`/project/projectDetails/:id`} component={NotFound} />
        <Route exact path={`/taste-quiz`} component={NotFound} />
        <Route exact path="/stoodio-snippet" component={NotFound} />
        <Route exact path="/artist-snippet" component={NotFound} />
        <Route exact path="/collector-snippet" component={NotFound} />
        <Route exact path="/admin-account" component={NotFound} />
        <Route exact path="/checkOut-snippet" component={NotFound} />
        <Route exact path="/my-purchases" component={NotFound} />
        <Route exact path="/manage-exhibition" component={NotFound} />
        <Route exact path={`/manage-early-work`} component={NotFound} />
        <Route exact path="/manage-seo-page" component={NotFound} />
        <Route exact path="/manage-news-events" component={NotFound} />
        
        <Route exact path="/hold-orders" component={NotFound} />
        <Route exact path="/news-events" component={NewsEventList} />
        <Route exact path="/news-events/:url" component={NewsDetails} />
        <Route exact path="/art-event-details/:event_title" component={NewsEventDetails} />
        
        {/* <Route exact path="*" component={NotFound} /> */}
      </Suspense>
    </Switch>
  );
};

export default AuthLayout(FrontendRoutes);
