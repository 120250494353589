import React, { useState, useEffect, useCallback } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons';
import { Form, Input, Button, Select, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AuthWrapper } from './style';
import { Checkbox } from '../../../../components/checkbox/checkbox';
import Heading from '../../../../components/heading/heading';
import {
    fbAuthSignUp,
    fbAuthLogin,
    fbAuthLoginWithGoogle,
    fbAuthLoginWithFacebook,
} from '../../../../redux/firebase/auth/actionCreator';
import { login } from '../../../../redux/authentication/actionCreator';
import { Modal } from '../../../../components/modals/antd-modals';
import PhoneInput from 'react-phone-input-2'
import FeatherIcon from 'feather-icons-react';
import 'react-phone-input-2/lib/style.css'
//import { CKEditor } from '@ckeditor/ckeditor5-react';
//import Editor from 'ckeditor5-custom-build/build/ckeditor';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import GetSnippetsData from '../../authentication/overview/Snippets/GetSnippetsData';
import axios from 'axios';
import config from '../../../../config/config';
import ReCAPTCHA from "react-google-recaptcha";
import messages from '../../../../config/constants/messages';
import JoditReactEditor from '../../../profile/settings/overview/JoditReactEditor';

import userServices from '../../../../services/userServices';

const SignUp = ({ visible, onCancel }) => {
    const { isSignUpError, isSignUpLoading, isFbAuthenticate } = useSelector(state => {
        return {
            isSignUpError: state.firebaseAuth.isSignUpError,
            isSignUpLoading: state.firebaseAuth.isSignUpLoading,
            isFbAuthenticate: state.fb.auth.uid,
        };
    });
    const history = useHistory();
    const dispatch = useDispatch();
    const role = "City"
    const snippetData = GetSnippetsData(role)

    const [state, setState] = useState({
        values: null,
        checked: null,
        visible
    });


    const handleFbLogin = useCallback(() => {
        dispatch(login());
        history.push('/gallery');

    }, [dispatch, history]);

    useEffect(() => {
        if (isFbAuthenticate) {
            handleFbLogin();
        }

        let unmounted = false;
        if (!unmounted) {
            setState({
                visible,
            });
        }
        return () => {
            unmounted = true;
        };
    }, [isFbAuthenticate, handleFbLogin, visible]);


    const [phone, setPhone] = useState('')
    const [bio, SetBio] = useState("")
    const handleCountryCode = (value, data, event, formattedValue) => {
        // console.log("value",value)
        // console.log("data",data)
        // console.log("event",event)
        // console.log("formattedValue",formattedValue)
        setPhone(formattedValue)
    }

    function removeHtmlTags(str) {
        str = str.replace(/<[^>]*>/g, '');
        return str.replace(/&nbsp;/g, '');
    }



    const handleSubmit = values => {

        if (bio == null || bio == '' || biolength == 0) {
            notification.warning({
                message: 'Please input your bio',
            });
            return false;
        }

        const cleanedString = removeHtmlTags(bio);
        if (cleanedString.length > 5000) {
            notification.warning({
                message: 'The maximum length for the bio is 5000 characters.'
            });
            return false;
        }

        //console.log({ ...values, phone: phone, terms: state.checked, bio: bio });


        const promise = new Promise((resolve, reject) => {

            let verify_token = { token: recaptcha };
            axios.post(`${config.apiUrl}/send-recaptcha-token`, verify_token)
                .then(function (response) {

                    if (response.data.google_response.success == true) {
                        resolve(true);
                        setRecaptcha('');
                    } else {
                        window.grecaptcha.reset();
                        setRecaptcha('');
                        setVerified(false);
                        alert(messages['recaptcha_failed_error'])
                        return false;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
        promise.then((d) => {
            window.grecaptcha.reset();
            var affinityArray = [];
            if (sessionStorage.getItem('gallery_likdislike_data')) {
                let user_choices = JSON.parse(sessionStorage.getItem('gallery_likdislike_data'))
                if (user_choices) {
                    for (let j = 0; j < user_choices.length; j++) {
                        let like = -2;
                        if (user_choices[j].likeDislike == 1) {
                            like = 1;
                        } else if (user_choices[j].likeDislike == 2) {
                            like = -1;
                        }
                        affinityArray.push({ id: user_choices[j].id, like: like });
                    }
                }
            }


            let post_data = { ...values, phone: phone, terms: state.checked, bio: bio };
            if (affinityArray.length) {
                post_data = { ...values, phone: phone, terms: state.checked, bio: bio, affinityArray: affinityArray };
            }

            const data = userServices.SignUp(post_data).then(response => {
                if (response.data.status == true) {
                    sessionStorage.setItem('signup_modal', JSON.stringify({
                        showmodal: true,
                        role: 'Collector'
                    }))
                    sessionStorage.setItem('save_rating', 1)
                    // notification.success({
                    //     message: response.data.message
                    // });
                    dispatch(fbAuthLogin(post_data));
                } else {
                    notification.warning({
                        message: response.data.message
                    });
                }
            }).
                catch(error => {
                    if (error.response && error.response.status === 400) {
                        console.log("Error 400: Bad Request");
                    } else {
                        console.error("An error occurred:", error);
                    }
                });

            /*
            dispatch(fbAuthSignUp({ ...values, phone: phone, terms: state.checked, bio: bio }))
                .then((res) => {

                    var UID = sessionStorage.getItem('UID');
                    console.log("create account res", res)

                    if (sessionStorage.getItem('gallery_likdislike_data')) {
                        let BrowserData = {
                            //tasteProfile: JSON.parse(sessionStorage.getItem('taste_profile')),
                            email: values.email,
                            //affinityScore: JSON.parse(sessionStorage.getItem('affinity_data')),
                            //tasteTestStatus: sessionStorage.getItem('taste_test_status'),
                            like_data: JSON.parse(sessionStorage.getItem('gallery_likdislike_data'))
                        }
                        axios.post(`${config.apiUrl}/store-calculation`, BrowserData)
                            .then(() => {

                                sessionStorage.removeItem('taste_profile');
                                sessionStorage.removeItem('taste_test_status');
                                sessionStorage.removeItem('gallery_likdislike_data');
                                sessionStorage.setItem('taste_view_visible', false);
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }


                })
                .catch((err) => {
                    console.log("Error : ", err)
                })
            */
        })


    };

    const handleSubmit1 = values => {
        console.log({ ...values, phone: phone, terms: state.checked, bio: bio });

        //return false;

        const promise = new Promise((resolve, reject) => {
            sessionStorage.setItem('signup_modal', JSON.stringify({
                showmodal: true,
                role: 'Collector'
            }))

            dispatch(fbAuthSignUp({ ...values, phone: phone, terms: state.checked, bio: bio }))
                .then((res) => {

                    var UID = sessionStorage.getItem('UID');
                    console.log("create account res", res)


                    if (sessionStorage.getItem('gallery_likdislike_data')) {
                        let BrowserData = {
                            //tasteProfile: JSON.parse(sessionStorage.getItem('taste_profile')),
                            email: values.email,
                            //affinityScore: JSON.parse(sessionStorage.getItem('affinity_data')),
                            //tasteTestStatus: sessionStorage.getItem('taste_test_status'),
                            like_data: JSON.parse(sessionStorage.getItem('gallery_likdislike_data'))
                        }
                        axios.post(`${config.apiUrl}/store-calculation`, BrowserData)
                            .then(() => {

                                sessionStorage.removeItem('taste_profile');
                                sessionStorage.removeItem('taste_test_status');
                                sessionStorage.removeItem('gallery_likdislike_data');
                                sessionStorage.setItem('taste_view_visible', false);
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }


                })
                .catch((err) => {
                    console.log("Error : ", err)
                })
            resolve(true);
        })
        promise.then((d) => {

        })

    };

    const onChange = checked => {
        setState({ ...state, checked });
    };
    //reducers
    const handleCancel = () => {
        onCancel();
    };

    const [recaptcha, setRecaptcha] = useState('')
    const [verified, setVerified] = useState(false);

    const [namelength, setNamelength] = useState(0)
    const [biolength, setBiolength] = useState(0)

    function onChange1(value) {
        setRecaptcha(value);
        setVerified(true);
    }

    const assign_bio = value => {
        const cleanedString = removeHtmlTags(value);
        setBiolength(cleanedString.length)
        SetBio(value);
    }





    return (
        <Modal
            type={state.modalType}
            title="Sign Up"
            visible={state.visible}
            footer={[]}
            onCancel={handleCancel}
        >
            <AuthWrapper>
                <div className="auth-contents">
                    <Form name="register" onFinish={handleSubmit} layout="vertical">
                        <p>
                            <b>Sign up for an account to track your ratings and build up your taste profile so you can see more of what you may like.</b>
                            {/* <span className="color-secondary">Stoodio</span> */}
                        </p>


                        <Form.Item name="user_type" initialValue="user" style={{ height: 0, display: 'none' }}>
                            <Input placeholder="User Type" type="hidden" />
                        </Form.Item>


                        {/* <Form.Item label="I am a..." name="i_am" rules={[{ required: true, message: 'Please select' }]}>
              <Select placeholder="Select from dropdown" name="i_am" >
                <Option value="Artist">Artist</Option>
                <Option value="Collector">Collector</Option>
              </Select>
            </Form.Item> */}

                        <Form.Item label="I am a..." name="i_am" initialValue="Collector">

                            <Input readOnly
                            />
                        </Form.Item>

                        {/* <Form.Item
              name="username"
              label="Username"
              rules={[{ required: true, message: 'Please input your username' }]}
            >
              <Input placeholder="Username" />
            </Form.Item> */}


                        <Form.Item label="Name" name="name"
                            rules={[
                                { required: true, message: 'Please input your full name' },
                                { max: 100, message: "The maximum length for the full name is 100 characters." }
                            ]}>

                            <Input placeholder="Full name"
                                onChange={(e) => {
                                    var temp = e.target.value;
                                    setNamelength(temp.length);
                                }
                                }
                            />
                        </Form.Item>
                        <div style={{ marginTop: '-25px', float: 'right' }} className={100 < namelength ? 'danger-text' : ''}>{namelength}/100 char used</div>
                        {/* <Form.Item
              name="username"
              label="Username"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input placeholder="Username" />
            </Form.Item> */}
                        <Form.Item
                            name="email"
                            label="Email Address"
                            rules={[
                                { required: true, message: 'Please input your email address' },
                                { type: 'email', message: 'Please validate your email address' },
                                { max: 100, message: "The maximum length for the email is 100 characters." }
                            ]}
                        >
                            <Input placeholder="name@example.com" />
                        </Form.Item>

                        <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Please input your phone' }]}>
                            {/* <Input placeholder="Phone" /> */}
                            <PhoneInput
                                country={'us'}
                                inputProps={{
                                    name: 'phone'
                                }}
                                inputStyle={{
                                    height: '48px',
                                    width: '100%'
                                }}
                                enableSearch={true}
                                copyNumbersOnly={false}
                                searchPlaceholder='search'
                                searchNotFound='No entries to show'
                                countryCodeEditable={false}
                                onChange={handleCountryCode}
                            />
                        </Form.Item>

                        {/* <Form.Item name="city" label="City" rules={[{ required: true, message: 'Please input your city!' }]}>
              <Input placeholder="City" />
            </Form.Item> */}

                        {/* <Form.Item style={{display:'none' }} name="country" value="usa" label="Country" >
              <Select style={{ width: '100%' }} >
                <Option value="usa">Please Select</Option>
                <Option value="usa">USA</Option>
              </Select>
            </Form.Item> */}
                        <Form.Item name="city" label="City" rules={[{ required: true, message: 'Please select your city' }]}
                            tooltip={{
                                title: <p dangerouslySetInnerHTML={{ __html: snippetData != "" ? snippetData.CityContent : "Currently we are only in the Chicago area, please send us a message if you think your location would be a good fit for Stoodio." }}></p>,
                                icon: <FeatherIcon icon="help-circle" size={20} />,
                                overlayClassName: 'ant-tooltip-custom-width'
                            }}>
                            <Input placeholder="City name" />
                            {/* <Select style={{ width: '100%', marginTop: 10 }} >
                                <Option value="">Please Select</Option>
                                <Option value="Chicago">Chicago</Option>
                            </Select>*/}
                        </Form.Item>




                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password' }]}
                        >
                            <Input.Password placeholder="Password" />
                        </Form.Item>

                        <Form.Item
                            name="confirm"
                            label="Confirm Password"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords that you entered do not match'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="Bio"
                            name="bio"
                            rules={[
                                { required: true, message: 'Please input your bio' },
                                { max: 5000, message: "The maximum length for the bio is 5000 characters." }]}
                        >

                            <Input.TextArea rows={4} type='text' onChange={(e) => assign_bio(e.target.value)} />
                            {/* <CKEditor
                            editor={ClassicEditor}
                            // config={editorConfiguration}
                            data={''}
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                              // console.log('Editor is ready to use!', editor)
                            }}
                            onChange={(event, editor) => { assign_bio(editor.getData()) }}
                            onBlur={(event, editor) => {
                              // console.log('Blur.', editor)
                            }}
                            onFocus={(event, editor) => {
                            }}

                            config={
                              {
                                ckfinder: {
                                  uploadUrl: `${process.env.REACT_APP_API_URL}/uploads`
                                },
                              }
                            }
                          />
                            <JoditReactEditor
                                SetEditorData={assign_bio}
                                initialValue={''}
                            />
                        */}
                        </Form.Item>
                        <div style={{ marginTop: '-25px', float: 'right' }} className={5000 < biolength ? 'danger-text' : ''}>{biolength}/5000 char used</div>

                        <Form.Item
                            name="checkbox"
                            rules={[
                                //{ required: true, message: 'Please select TOS and Privay Policy!' }]}
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Please agree to TOS and Privacy Policy'));
                                    },
                                }),
                            ]}
                        >
                            <Checkbox checked={state.checked} onChange={onChange}>
                                I agree to the <NavLink to={`/terms`} target='_blank'>Terms of Service</NavLink> and <NavLink to={`/privacy-policy`} target='_blank'>Privacy Policy</NavLink>
                            </Checkbox>
                        </Form.Item>

                        <Form.Item>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                onChange={onChange1}
                            />
                        </Form.Item>
                        {isSignUpError ? <p style={{ color: 'red' }}>{isSignUpError.message}</p> : null}
                        <Form.Item>
                            <Button className="btn-create" htmlType="submit" type="primary" size="large" disabled={!verified}>
                                {isSignUpLoading ? 'Loading...' : 'Create Account'}
                            </Button>
                        </Form.Item>
                        {/* <p className="form-divider">
                            <span>Or</span>
                        </p> */}
                        {/* <p>Sign in with the following apps below</p> */}
                        <ul className="social-login ">
                            {/* 
                            <li>
                                <Link onClick={() => dispatch(fbAuthLoginWithGoogle("Collector"))} className="google-signup" to="#">
                                    <img src={require('../../../../static/img/google.png')} alt="" />
                                </Link>
                            </li>
                            <li>
                                <Link onClick={() => dispatch(fbAuthLoginWithFacebook)} className="facebook-sign" to="#">
                                    <FacebookOutlined />
                                </Link>
                            </li>
                            <li>
                                <Link className="twitter-sign" to="#">
                                    <TwitterOutlined />
                                </Link>
                            </li>
                            */}
                        </ul>
                    </Form>
                </div>
            </AuthWrapper>
        </Modal>
    );
};

export default SignUp;
