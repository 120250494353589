import { Button, Col, Row } from 'antd'
import React from 'react'
import GetSnippetsData from '../Snippets/GetSnippetsData'
import './css/style.css'
import AdminAccount from './AdminAccount';
import CommonFooter from "../CommonFooter";
import colorwheel from '../../../../../static/img/homeColorwheel.png';
import ImageSwiper from './ImageScroll';
import { Helmet } from 'react-helmet';
import CommonSEO from '../../../../meta/CommonSEO';

const HomePageContent = () => {

    const role = "Home"
    const snippetData = GetSnippetsData(role)
    // console.log('snippetData',snippetData.homeContent.split("<br>"))


    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);

    const handleModalCancel = () => {
        setVisible(false);
    };

    const showModal = () => {
        setVisible(true);
    };

    const steps = [
        {
            id: '0',
            message: 'Welcome to react chatbot!',
            trigger: '1',
        },
        {
            id: '1',
            message: 'Bye!',
            end: true,
        },
    ];
    const imageUrls = [
        // 'https://stoodio-dev-images.s3.amazonaws.com/Images/BodyandSoulFromtheHeartOfGod-1690306679646.jpg',
        // 'https://stoodio-dev-images.s3.amazonaws.com/Images/Disqualified-1676496527569.jpg',
        // 'https://stoodio-dev-images.s3.amazonaws.com/Images/FindingOurWay-1676847053253.jpg',
        // 'https://stoodio-dev-images.s3.amazonaws.com/Images/KitesFlyingThroughLife-1676847962897.jpg',
        // Add more image URLs as needed
    ];

    const createUserSession = async () => {
        const userCredentials = { login: 'YOUR_USERNAME', password: 'YOUR_PASSWORD' };

        try {
            const session = await QB.auth.createSession(userCredentials);
            console.log('User session created:', session);
        } catch (error) {
            console.error('Error creating user session:', error);
        }
    };

    // Call the function when needed, such as in a button click event
    const handleLogin = () => {
        createUserSession();
    };




    return (
        <div style={{ width: '100%' }}>
            <CommonSEO URL=""/>
            <AdminAccount visible={visible} confirmLoading={confirmLoading} onCancel={handleModalCancel} />
            <div style={{ minHeight: 1000 }}>
                <Row className='set-homepage-content'>
                    <div className='adjust-homepage-block'>
                        <h1 className='home-content-heading' dangerouslySetInnerHTML={{ __html: snippetData != "" ? snippetData.homeContent.split("<br>")[0] : "" }} >
                        </h1>
                        <p className='home-content-title' dangerouslySetInnerHTML={{ __html: snippetData != "" ? snippetData.homeContent.split("<br>")[1] : "" }}></p>
                        <button style={{ cursor: 'pointer', marginTop: '2em' }} size='default' class="slide-from-bottom add-cart-button-text" onClick={() => showModal()}>
                            <p className='header-menu-font' style={{ margin: 0, fontWeight: 600, lineHeight: '24px', letterSpacing: ' 0em', textAlign: 'left' }}>Explore Gallery</p>
                        </button>
                        <p className='home-content-sub-title' dangerouslySetInnerHTML={{ __html: snippetData != "" ? snippetData.homeContent.split("<br>")[2] : "" }}>
                        </p>
                    </div>
                    <ImageSwiper images={imageUrls} />
                </Row>
            </div>
            <div className="adjust-home-page-footer" style={
                Object.assign({}
                    , {
                        width: '-webkit-fill-available',
                        position: 'absolute',
                        left: 0,
                        // bottom: 0,
                        right: 0,
                    }
                )}
            >
                <CommonFooter />
            </div>
        </div>
    )
}

export default HomePageContent