/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Layout, Button, Row, Col, Badge, Menu, Dropdown } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import MenueItems from './MenueItems';
import { DownOutlined } from '@ant-design/icons';
import { Div, SmallScreenAuthInfo } from './style';
import AuthInfo from '../components/utilities/auth-info/info';
import UserAuthInfo from '../components/utilities/auth-info/userinfo';
import { changeRtlMode, changeLayoutMode, changeMenuMode } from '../redux/themeLayout/actionCreator';
import Logo from '../static/img/stoodiologo.svg';
//import Cart from '../static/img/icon/shopping-cart.svg'
import '../container/profile/authentication/overview/Navbar.css'
import '../container/profile/authentication/overview/Navbar1.css'
import Modal from 'antd/lib/modal/Modal';
import TasteQuiz from '../container/profile/authentication/overview/TasteQuiz';
import Cart from '../static/img/shop-cart.svg'
import Hamburger from '../static/img/hamburger-Icon.svg'
import CloseIcon from '../static/img/close-Icon.svg'
import CountCart from '../static/img/cart-icon.svg'
import { useSelector } from 'react-redux';
const { darkTheme } = require('../config/theme/themeVariables');

const { Header, Footer, Sider, Content } = Layout;


// const { darkMode } = config;

const ThemeLayout = WrappedComponent => {

  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: false,
        hide: true,
        searchHide: true,
        activeSearch: false,
        items: [],
        click: false,
        artistLoading: false,
        artistVisible: false,
        collectorLoading: false,
        collectorVisible: false,
        showToggle: false,
        isUserLogData: null,
        visible: false,
        galleryMenu: false

      };
      this.updateDimensions = this.updateDimensions.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.Close = this.Close.bind(this);
      this.showAdminTasteQuizModal = this.showAdminTasteQuizModal.bind(this);
      this.showAdminTasteQuizWithoutModal = this.showAdminTasteQuizWithoutModal.bind(this);
      this.handleAdminTasteQuizOk = this.handleAdminTasteQuizOk.bind(this);
      this.handleAdminTasteQuizCancel = this.handleAdminTasteQuizCancel.bind(this);
      this.showGalleryNotification = this.showGalleryNotification.bind(this);
      this.notificationClose = this.notificationClose.bind(this);
    }





    componentDidMount() {
      // const UID = useSelector((state) => {
      //   return state.fb.auth.uid;
      // });


      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
      // fetch(`${config.apiUrl}/get-cart-details/g2VImWqFuKbiNtxXkl9fHR3Zn712`)
      // .then(response => response.json())
      // .then(data => {
      //   this.setState({
      //     items: data
      //   });
      // });
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    handleModal = () => {
      this.setState({
        showToggle: !this.state.showToggle,
      });
    }

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    handleClick() {
      this.setState({
        click: !this.state.click
      })
    }

    Close() {
      this.setState({
        click: false
      })
    }


    /* For Artist Modal */

    showArtistModal = () => {
      this.setState({
        artistVisible: true,
      });
    };

    notificationClose = () => {
      console.log(
        'Notification was closed. Either the close button was clicked or duration time elapsed.',
      );
    };


    showGalleryNotification = () => {

      const tasteTestStatus = sessionStorage.getItem('taste_test_status')
      const save_rating = sessionStorage.getItem('save_rating');

      if (tasteTestStatus) {
        if (tasteTestStatus == 1) {
          console.log("completed")
        } else {
          console.log("Notcompleted")
          const key = `open${Date.now()}`;
          /*
          notification.open({
            message: 'Please complete taste test first',
            key,
            onClose: this.notificationClose,
          });
          {"showmodal":true,"role":"Collector"}
          */

          sessionStorage.setItem('taste_view_visible', false);
        }
      }
    };


    handleArtistOk = () => {
      this.setState({ artistLoading: true });
      setTimeout(() => {
        this.setState({ artistLoading: false, artistVisible: false });
      }, 3000);
    };

    handleArtistCancel = () => {
      this.setState({ artistVisible: false });
    };

    /* For Collector Modal */
    showCollectorModal = () => {
      this.setState({
        collectorVisible: true,
      });
    };

    handleCollectorOk = () => {
      this.setState({ collectorLoading: true });
      setTimeout(() => {
        this.setState({ collectorLoading: false, collectorVisible: false });
      }, 3000);
    };

    handleCollectorCancel = () => {
      this.setState({ collectorVisible: false });
    };


    // Taste Quiz model code 
    showAdminTasteQuizModal = () => {
      sessionStorage.setItem('visible', true);
    }

    checkPage = () => {
      this.props.history.push('/checkout')
    }


    showAdminTasteQuizWithoutModal = () => {
      sessionStorage.setItem('taste_view_visible', true);
      this.props.history.push('/taste-test-quiz');
    }


    handleAdminTasteQuizOk = () => {
      this.setState({ visible: false });
      sessionStorage.removeItem('visible');
    };

    handleAdminTasteQuizCancel = () => {
      this.setState({ visible: false });
      sessionStorage.removeItem('visible');
    };


    render() {


      const { collapsed, hide, searchHide, activeSearch } = this.state;
      const { ChangeLayoutMode, rtl, topMenu, changeRtl, changeLayout, CountData, UserLogData } = this.props;

      const left = !rtl ? 'left' : 'right';
      const darkMode = ChangeLayoutMode;
      const toggleCollapsed = () => {
        this.setState({
          collapsed: !collapsed,
        });
      };

      const toggleCollapsedMobile = () => {
        if (window.innerWidth <= 990) {
          this.setState({
            collapsed: !collapsed,
          });
        }
      };

      const onShowHide = () => {
        this.setState({
          hide: !hide,
          searchHide: true,
        });
      };

      const toggleSearch = () => {
        this.setState({
          activeSearch: !activeSearch,
        });
      };

      const handleSearchHide = e => {
        e.preventDefault();
        this.setState({
          searchHide: !searchHide,
          hide: true,
        });
      };

      const SideBarStyle = {
        margin: '80px 0 0 0',
        padding: '15px 0px 55px 0px',
        overflowY: 'auto',
        height: '100vh',
        position: 'fixed',
        // [left]: 0,
        zIndex: 998,
      };

      const renderView = ({ style, ...props }) => {
        const customStyle = {
          marginRight: 'auto',
          [rtl ? 'marginLeft' : 'marginRight']: '-17px',
        };
        return <div {...props} style={{ ...style, ...customStyle }} />;
      };

      const renderThumbVertical = ({ style, ...props }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
          [left]: '2px',
        };
        return <div style={{ ...style, ...thumbStyle }} props={props} />;
      };

      const renderTrackVertical = () => {
        const thumbStyle = {
          position: 'absolute',
          width: '6px',
          transition: 'opacity 200ms ease 0s',
          opacity: 0,
          [rtl ? 'left' : 'right']: '2px',
          bottom: '2px',
          top: '2px',
          borderRadius: '3px',
        };
        return <div style={thumbStyle} />;
      };

      const renderThumbHorizontal = ({ style, ...props }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
        };
        return <div style={{ ...style, ...thumbStyle }} props={props} />;
      };

      const onRtlChange = () => {
        const html = document.querySelector('html');
        html.setAttribute('dir', 'rtl');
        changeRtl(true);
      };

      const onLtrChange = () => {
        const html = document.querySelector('html');
        html.setAttribute('dir', 'ltr');
        changeRtl(false);
      };

      const modeChangeDark = () => {
        changeLayout(true);
      };

      const modeChangeLight = () => {
        changeLayout(false);
      };

      const modeChangeTopNav = () => {
        changeMenuMode(true);
      };

      const modeChangeSideNav = () => {
        changeMenuMode(false);
      };

      const onEventChange = {
        onRtlChange,
        onLtrChange,
        modeChangeDark,
        modeChangeLight,
        modeChangeTopNav,
        modeChangeSideNav,
      };


      const { items } = this.state;

      const count = CountData.length;

      //const cartItemCount = Number(sessionStorage.getItem('cartItemCount'))

      const userTasteQuizData = UserLogData[0]

      var singleTasteQuiz = userTasteQuizData

      var tasteQuizStatus = 1
      const tasteTestStatus = sessionStorage.getItem('taste_test_status')
      const tasteTestStatusVisible = sessionStorage.getItem('taste_test_status_visible')

      if (tasteTestStatus) {
        if (tasteTestStatus == 1) {
          tasteQuizStatus = 1
        } else {
          tasteQuizStatus = 0
        }
      }

      if (tasteTestStatusVisible) {
        if (tasteTestStatusVisible == 1) {
          tasteQuizStatus = 1
        } else {
          tasteQuizStatus = 0
        }
      }


      const redirecturl = () => {
        window.location = '/gallery';
      }

      const { visible } = this.state;

      const { artistVisible, artistLoading } = this.state;
      const { collectorVisible, collectorLoading } = this.state;

      return (
        <Div darkMode={darkMode}>
          <>
            <Modal
              visible={artistVisible}
              title="For Artists"
              onOk={this.handleArtistOk}
              onCancel={this.handleArtistCancel}
              footer={[]}
            >
              <>
                <p style={{ fontSize: 16 }}>
                  After signing up, you will be able to create your artist’s profile and upload artworks you wish to sell.
                  You will be notified and paid as soon as one of your artworks is sold.
                </p><p style={{ fontSize: 16 }}>
                  Stoodio will only take 15%
                  commission on sales, as compared to 50% or more, which a gallery commonly charges.
                </p><p style={{ fontSize: 16 }}>
                  We tailor which artworks a collector sees based on their personal taste profile.
                  to see how we do this, or to start collecting artworks yourself, click on browse artworks.
                </p>
              </>
            </Modal>

            <Modal
              visible={collectorVisible}
              title="For Collectors"
              onOk={this.handleCollectorOk}
              onCancel={this.handleCollectorCancel}
              footer={[]}
            >
              <>
                <p style={{ fontSize: 16 }}>
                  Welcome! Stoodio is here to help you discover artworks by leading emerging artists
                  that are right for you. Our aim is to offer you, a collector and an art lover,
                  a reliable and effective way of discovering and purchasing artwork
                  without the need to navigate the gallery world.
                </p>
                <p style={{ fontSize: 16 }}>
                  Please note that we currently only
                  are able to list two-dimensional,
                  physical artworks (paintings, photography, prints, and the like).
                </p>
              </>
            </Modal>

            <Modal title="Taste Test"
              visible={sessionStorage.getItem('visible')
                ?
                !this.state.visible
                :
                visible}
              onOk={this.handleAdminTasteQuizOk}
              onCancel={this.handleAdminTasteQuizCancel}
              footer={null}
              width={1000}>
              <TasteQuiz />
            </Modal>
          </>


          {
            (window.location.pathname.includes('/checkout')
              || window.location.pathname.includes('/for-artists')
              || window.location.pathname.includes('/for-collectors')
              || window.location.pathname.includes('/terms')
              || window.location.pathname.includes('/privacy-policy')
              || window.location.pathname.includes('/our-partners')
              || window.location.pathname.includes('/exhibitions')
              || window.location.pathname.includes('/blogs')
              || window.location.pathname.includes('/education')
              || window.location.pathname.includes('/gallery')
              || window.location.pathname.includes('/all-artworks')
              || window.location.pathname.includes('/artworks')
              || window.location.pathname.includes('/artists')
              || window.location.pathname.includes('/purchase-sucess')
              || window.location.pathname.includes('/data-not-found')
              || window.location.pathname.includes('/taste-test-quiz')
              || window.location.pathname.includes('/artwork-for-sale')
              || window.location.pathname.includes('/news-events')
              || window.location.pathname.includes('/art-event-details')
              || window.location.pathname.includes('/early-work')
              || window.location.pathname.includes('/early-access')

            ) ?
              (
                <Layout className="layout">
                  {
                    <Header
                      style={{
                        height: 72,
                        position: 'fixed',
                        width: '100%',
                        top: 0,
                        zIndex: 2,
                        //boxShadow: "0 5px 10px -10px rgba(0, 0, 0, 0.6)"
                        boxShadow: "none"
                      }}
                    >
                      <Row>
                        <Col span={24}>
                          <div>
                            <div className={this.state.click ? "main-container" : ""} onClick={this.Close} />
                            <nav className="navbar" onClick={(e) => e.stopPropagation()}>
                              <div className="nav-container">

                                <NavLink exact to="#" className="nav-logo after-login-logo" style={{ cursor: 'auto', width: '15%' }}>
                                  <img src={Logo} alt="logo" width="130" style={{ cursor: 'pointer' }} onClick={redirecturl} />
                                </NavLink>
                                <div className='after-login-header-menu' style={{ width: '75%' }}>
                                  <ul className={this.state.click ? "nav-menu active" : "nav-menu"}>
                                    <li className="nav-item">
                                      <Link
                                        exact
                                        to="/all-artworks"
                                        activeclassname="active"
                                        className="nav-links hamburger-menu-color header-menu-font "
                                        style={{ fontSize: 13, fontWeight: 600 }}
                                        // onClick={() => { this.showCollectorModal(), this.state.click ? this.handleClick() : null }}
                                        onClick={() => { this.state.click ? this.handleClick() : null }}
                                      >
                                        All Artworks
                                      </Link>
                                    </li>

                                    <li className="nav-item">
                                      <Link
                                        exact
                                        to="/gallery"
                                        activeclassname="active"
                                        className="nav-links hamburger-menu-color header-menu-font"
                                        style={{ fontSize: 13, fontWeight: 600 }}
                                        onClick={() => { this.showGalleryNotification(), this.state.click ? this.handleClick : null }}
                                      >
                                        Gallery
                                      </Link>
                                    </li>

                                    <li className="nav-item">
                                      <Link
                                        exact
                                        to="/artists"
                                        activeclassname="active"
                                        className="nav-links hamburger-menu-color header-menu-font "
                                        style={{ fontSize: 13, fontWeight: 600 }}
                                        // onClick={() => { this.showCollectorModal(), this.state.click ? this.handleClick() : null }}
                                        onClick={() => { this.state.click ? this.handleClick() : null }}
                                      >
                                        Artists
                                      </Link>
                                    </li>

                                    <li className="nav-item">
                                      <Link
                                        exact
                                        to="/for-collectors"
                                        activeclassname="active"
                                        className="nav-links hamburger-menu-color header-menu-font "
                                        style={{ fontSize: 13, fontWeight: 600 }}
                                        // onClick={() => { this.showCollectorModal(), this.state.click ? this.handleClick() : null }}
                                        onClick={() => { this.state.click ? this.handleClick() : null }}
                                      >
                                        For Collectors
                                      </Link>
                                    </li>


                                    <li className="nav-item">
                                      <Link
                                        exact
                                        to="/for-artists"
                                        activeclassname="active"
                                        className="nav-links artist-marging hamburger-menu-color header-menu-font"
                                        style={{ fontSize: 13, fontWeight: 600 }}
                                        // onClick={() => { this.showArtistModal(), this.state.click ? this.handleClick() : null }}
                                        onClick={() => { this.state.click ? this.handleClick() : null }}
                                      >
                                        For Artists
                                      </Link>
                                    </li>

                                    {
                                      tasteQuizStatus &&
                                        tasteQuizStatus == 1 ?
                                        ""
                                        :
                                        <li className="nav-item mobile-view">
                                          <Link
                                            exact
                                            to="#"
                                            activeclassname="active"
                                            className="nav-links hamburger-menu-color header-menu-font header-menu-font"
                                            style={{ fontSize: 13, fontWeight: 600 }}
                                            onClick={() => { this.showAdminTasteQuizWithoutModal(), this.state.click ? this.handleClick() : null }}
                                          >
                                            Taste Test
                                            <Badge dot className='taste-test-color'>
                                            </Badge>
                                          </Link>
                                        </li>
                                    }

                                    <li className="nav-item">
                                      <Link
                                        exact
                                        to="/exhibitions"
                                        activeclassname="active"
                                        className="nav-links hamburger-menu-color header-menu-font "
                                        style={{ fontSize: 13, fontWeight: 600 }}
                                        // onClick={() => { this.showCollectorModal(), this.state.click ? this.handleClick() : null }}
                                        onClick={() => { this.state.click ? this.handleClick() : null }}
                                      >
                                        Exhibitions
                                      </Link>
                                    </li>

                                    <li
                                    className="nav-item"
                                    style={{ fontWeight: 600 }}
                                    >
                                      <Dropdown
                                        overlay={
                                          <Menu>
                                            {/* <Menu.Item key="blogs">
                                              <Link to="/blogs">Stoodio Blog</Link>
                                            </Menu.Item> */}
                                            <Menu.Item key="education">
                                              <Link style={{ fontSize: 13, fontWeight: 600 }} className="nav-links hamburger-menu-color header-menu-font header-menu-font" to="/education">Stoodio Education</Link>
                                            </Menu.Item>
                                            <Menu.Item key="news-events">
                                              <Link style={{ fontSize: 13, fontWeight: 600 }} className="nav-links hamburger-menu-color header-menu-font header-menu-font" to="/news-events">Stoodio News and Events</Link>
                                            </Menu.Item>
                                          </Menu>
                                        }
                                      >
                                        <a
                                          style={{ fontSize: 13, fontWeight: 600 }}
                                          className="nav-links hamburger-menu-color header-menu-font "
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          Explore More <DownOutlined />
                                        </a>
                                      </Dropdown>
                                    </li>

                                    {/* 
                                    <li className="nav-item">
                                      <Link
                                        exact
                                        to="/news-events"
                                        activeclassname="active"
                                        className="nav-links hamburger-menu-color header-menu-font "
                                        style={{ fontSize: 13, fontWeight: 600 }}
                                        // onClick={() => { this.showCollectorModal(), this.state.click ? this.handleClick() : null }}
                                        onClick={() => { this.state.click ? this.handleClick() : null }}
                                      >
                                        Events
                                      </Link>
                                    </li>                                    
                                    <li className="nav-item">
                                      <Link
                                        exact
                                        to="/education"
                                        activeclassname="active"
                                        className="nav-links hamburger-menu-color header-menu-font "
                                        style={{ fontSize: 13, fontWeight: 600 }}
                                        // onClick={() => { this.showCollectorModal(), this.state.click ? this.handleClick() : null }}
                                        onClick={() => { this.state.click ? this.handleClick() : null }}
                                      >
                                        Education
                                      </Link>
                                    </li> */}

                                    {/* <li className="nav-item hide-cart">
                                      <Link
                                        exact
                                        to="/checkout"
                                        activeclassname="active"
                                        className="menu-cart"
                                        style={{ fontSize: 13, fontWeight: 600, textTransform: 'uppercase' }}

                                        onClick={this.state.click ? this.handleClick : null}
                                      >
                                        <Badge count={cartItemCount ? cartItemCount : count}>
                                          <img src={Cart} alt='Cart' />
                                        </Badge>
                                      </Link>
                                    </li> */}
                                    <li style={{ justifyContent: 'center' }} className='hide-cart1'>
                                      <AuthInfo />
                                    </li>
                                  </ul>
                                </div>

                                <div className='after-login-circle' style={{ width: '10%', textAlign: 'right', marginRight: 25, display: 'flex', justifyContent: 'flex-end' }}>
                                  {
                                    tasteQuizStatus &&
                                      tasteQuizStatus == 1 ?
                                      ""
                                      :
                                      <button
                                        className="login-blue-button after-login desktop-view"
                                        style={{ textWrap: 'nowrap' }}
                                        onClick={this.showAdminTasteQuizWithoutModal}
                                      >
                                        Taste Test
                                      </button>
                                  }
                                  <button
                                    className={count ? "login-blue-button after-login" : "padding-top-cart-zero cart-zero with-login-cart-zero"}
                                    //style={{marginTop:18}}
                                    onClick={this.checkPage}
                                    style={{}}
                                  //style={{ fontWeight: 600, height: 35, fontSize: 11, textTransform: 'uppercase', marginBottom: 10 }}
                                  >
                                    <Badge count={count ? count : 0} >
                                      <img src={count ? CountCart : Cart} alt='Cart' className={count ? "" : "add-cart-image"} />
                                    </Badge>
                                  </button>

                                  {/* <div className="">
                                    <Link
                                      exact
                                      to="/checkout"
                                      activeclassname="active"
                                      className="menu-cart"
                                      style={{ fontSize: 13, fontWeight: 600, textTransform: 'uppercase' }}

                                      onClick={this.state.click ? this.handleClick : null}
                                    >
                                      <Badge count={cartItemCount ? cartItemCount : count}>
                                        <img src={Cart} alt='Cart' style={{ height: 30 }} />
                                      </Badge>
                                    </Link>
                                  </div> */}

                                  <div className='desktop-login-button'>
                                    <AuthInfo />
                                  </div>

                                  <div className="nav-icon" onClick={this.handleClick}>
                                    {/* <i className={this.state.click ? "fa fa-times" : "fa fa-align-left"}></i> */}
                                    {this.state.click ?
                                      <img src={CloseIcon} alt='close' />
                                      :
                                      <img src={Hamburger} alt='Cart' />
                                    }
                                  </div>

                                </div>
                              </div>
                            </nav>
                          </ div>
                        </Col>
                      </Row>
                    </Header>
                  }

                  <Layout>
                    {/* <Layout className={!window.location.pathname.includes('/checkout') ? "atbd-main-layout" : ""}> */}
                    <Layout >
                      {/* <Layout className="atbd-main-layout"> */}
                      <Content>
                        <WrappedComponent {...this.props} />
                      </Content>
                    </Layout>

                  </Layout>
                </Layout>
              )
              :
              (
                // InSide Login menu bar code
                <Layout className="layout">
                  {
                    <>
                      <Header
                        style={{
                          position: 'fixed',
                          width: '100%',
                          top: 0,
                          [!rtl ? 'left' : 'right']: 0,
                        }}
                      >
                        <Row>
                          <Col lg={!topMenu ? 4 : 3} sm={6} xs={12} className="align-center-v navbar-brand">

                            <Link
                              className={topMenu && window.innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'}
                              to="/gallery"
                            >
                              <img
                                src={Logo}
                                alt="stoodio logo"
                                style={{ marginLeft: 15 }}
                              />
                            </Link>
                          </Col>

                          <Col lg={20} md={18} sm={18} xs={12}>
                            <>
                              <div className="mobile-action">
                                <button
                                  //className={count ? "after-login-blue-button" : "cart-zero margin-cart-zero"}
                                  className={count ? "login-blue-button after-login-blue-button" : "cart-zero margin-cart-zero"}
                                  onClick={this.checkPage}
                                //style={{ fontWeight: 600, height: 35, fontSize: 11, textTransform: 'uppercase', marginBottom: 10 }}
                                >
                                  <Badge count={count ? count : 0} >
                                    <img src={count ? CountCart : Cart} alt='Cart' className={count ? "" : "add-cart-image-login"} />
                                  </Badge>
                                </button>

                                <span className='user-profile'>
                                  {/* <AuthInfo /> */}
                                  <UserAuthInfo />
                                </span>
                                <Link className="btn-auth" onClick={onShowHide} to="#">
                                  <span className='three-dot-menu-icon'><FeatherIcon icon="more-vertical" /></span>
                                </Link>
                              </div>
                            </>
                          </Col>
                        </Row>
                      </Header>
                      <div className="header-more">
                        <Row>
                          <Col md={0} sm={24} xs={24}>
                            <div className="small-screen-headerRight">
                              <SmallScreenAuthInfo hide={hide} darkMode={darkMode}>
                                <AuthInfo rtl={rtl} />
                              </SmallScreenAuthInfo>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </>
                  }

                  <Layout style={
                    Object.assign({}
                      , {
                        top: 72,
                        position: 'fixed',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        height: 'calc(100% - 72px)',
                        width: '100%',
                      }
                    )}
                  >
                    {/* Do not show side bar if it is home page */}
                    {
                      ((!topMenu || window.innerWidth <= 991)) ? (

                        <ThemeProvider theme={darkTheme}>
                          <i style={{ fontSize: 25, margin: 5, height: 50 }} onClick={this.handleModal} className={this.state.showToggle ? 'fa fa-align-left show-sidebar-menu hide-toggle-menubar-icon' : 'fa fa-align-left hide-sidebar-menu hide-toggle-menubar-icon'} />
                          <Sider className={this.state.showToggle ? 'show-sidebar-menu' : 'hide-sidebar-menu'} style={SideBarStyle} collapsed={collapsed} theme={!darkMode ? 'light' : 'dark'}>
                            <Scrollbars
                              className="custom-scrollbar"
                              autoHide
                              autoHideTimeout={500}
                              autoHideDuration={200}
                              renderThumbHorizontal={renderThumbHorizontal}
                              renderThumbVertical={renderThumbVertical}
                              renderView={renderView}
                              renderTrackVertical={renderTrackVertical}
                            >
                              {/* <p className="sidebar-nav-title">MAIN MENU</p> */}
                              <MenueItems
                                topMenu={topMenu}
                                rtl={rtl}
                                toggleCollapsed={toggleCollapsedMobile}
                                darkMode={darkMode}
                                events={onEventChange}
                              />
                            </Scrollbars>
                          </Sider>
                        </ThemeProvider>
                      ) : null
                    }

                    {/* <Layout className={!window.location.pathname.includes('/gallery') ? "atbd-main-layout" : ""}> */}
                    <Layout className="atbd-main-layout">
                      <Content style={
                        Object.assign({}
                          , {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                          }
                        )}
                      >
                        <WrappedComponent {...this.props} />
                      </Content>
                    </Layout>
                  </Layout>
                </Layout>
              )
          }
        </Div>
      );
    }
  }

  const mapStateToProps = state => {
    //console.log("UsersReducer data",state.UsersReducer.data)
    return {
      ChangeLayoutMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      CountData: state.CountReducer.data,
      UserLogData: state.UsersReducer.data,
    };
  };

  const mapStateToDispatch = dispatch => {
    return {
      changeRtl: rtl => dispatch(changeRtlMode(rtl)),
      changeLayout: show => dispatch(changeLayoutMode(show)),
      changeMenuMode: show => dispatch(changeMenuMode(show)),
    };
  };

  LayoutComponent.propTypes = {
    ChangeLayoutMode: propTypes.bool,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
    changeRtl: propTypes.func,
    changeLayout: propTypes.func,
    changeMenuMode: propTypes.func,
  };

  return connect(mapStateToProps, mapStateToDispatch)(LayoutComponent);
};
export default ThemeLayout;